import React from "react";
import Typography from "@mui/material/Typography";

const Home = () => {
  return (
    <div className="home container">
      <div className="content center">
        <Typography variant="h2" gutterBottom component="div">
          The rise approaches...
        </Typography>
        <a href="https://twitter.com/mycelium_rising" target="_blank">
          <i class="fab fa-twitter"></i>
        </a>
        <br />
        <br />
        <a href="https://discord.com/invite/3fqQutU36u" target="_blank">
          <i class="fab fa-discord"></i>
        </a>
      </div>
    </div>
  );
};

export default Home;
