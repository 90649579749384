import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";

// Content
import Home from "./content/home";
import NotFound from "./content/not-found";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
